@use 'bootstrap/scss/bootstrap' with (
  $enable-negative-margins: true,
  // Uncomment to customize theme colors
  // $theme-colors: (
  //   'info': tomato,
  //   'danger': teal,
   // )
);

.monospace-text {
  font-family: 'Roboto Mono', monospace;
}

.navbar-custom .nav-links .nav-link {
  font-size: 1.25rem; /* Increase font size */
}

.navbar-custom .nav-links .nav-link:hover {
  text-decoration: underline; /* Optional: Add underline on hover for better visibility */
}
